.overageBillingJobStatus > .buttonArea {
  display: flex;
  justify-content: space-between;
}

.overageBillingJobStatus .ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  line-height: 40px;
}

.overageBillingJobStatus .buttonArea {
  padding-top: 10px;
}

.overageBillingJobStatus .buttonArea button:last-child {
  margin-right: -16px;
}

.overageBillingJobStatus .ant-row.ant-form-item:last-child {
  top: 0px;
}

/**************************************************
 * 一覧内のポップオーバーの設定
 **************************************************/
.overageBillingJobStatus .commonTable .popoverPre {
  font-family: "Roboto", "Noto Sans JP", Helvetica, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
    "Meiryo UI", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", serif;
}

/**************************************************
 * 一覧の各種件数欄の設定
 **************************************************/
.overageBillingJobStatus .commonTable .count .ant-table-column-title {
  white-space: pre-wrap;
}

/**************************************************
 * 一覧の処理欄の設定
 **************************************************/
.overageBillingJobStatus .commonTable .ant-table-tbody td.processName {
  white-space: nowrap;
  max-width: 160px;
}
.overageBillingJobStatus .commonTable .ant-table-tbody div.processName {
  overflow: hidden;
  text-overflow: ellipsis;
}
.overageBillingJobStatus .commonTable .processNamePopover {
  width: 100% !important;
  max-width: 800px !important;
}

/**************************************************
 * 一覧のエラーメッセージ欄の設定
 **************************************************/
.overageBillingJobStatus .commonTable .ant-table-tbody td.errorMessage {
  white-space: nowrap;
  max-width: 320px;
}
.overageBillingJobStatus .commonTable .ant-table-tbody div.errorMessage {
  overflow: hidden;
  text-overflow: ellipsis;
}
.overageBillingJobStatus .commonTable .errorMessagePopover {
  width: 100% !important;
  max-width: 800px !important;
}

/**************************************************
 * 一覧の各種日時欄の設定
 **************************************************/
.overageBillingJobStatus .commonTable .ant-table-tbody td.datetimeColumn {
  white-space: pre-wrap;
}
