.overageBillingJobErrorDetails .titleArea {
  padding-top: 10px;
}

.overageBillingJobErrorDetails .titleArea h3 {
  margin-bottom: 0px;
}

/**************************************************
 * 一覧内のポップオーバーの設定
 **************************************************/
.overageBillingJobErrorDetails .commonTable .popoverPre {
  font-family: "Roboto", "Noto Sans JP", Helvetica, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro",
    "Meiryo UI", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", serif;
}

/**************************************************
 * 一覧の各種件数欄の設定
 **************************************************/
.overageBillingJobErrorDetails .commonTable .count .ant-table-column-title {
  white-space: pre-wrap;
}

/**************************************************
 * 一覧の企業名欄の設定
 **************************************************/
.overageBillingJobErrorDetails .commonTable .ant-table-tbody td.companyName {
  white-space: nowrap;
  max-width: 160px;
}
.overageBillingJobErrorDetails .commonTable .ant-table-tbody div.companyName {
  overflow: hidden;
  text-overflow: ellipsis;
}
.overageBillingJobErrorDetails .commonTable .companyNamePopover {
  width: 100% !important;
  max-width: 800px !important;
}

/**************************************************
 * 一覧のエラーメッセージ欄の設定
 **************************************************/
.overageBillingJobErrorDetails .commonTable .ant-table-tbody td.errorMessage {
  white-space: nowrap;
  max-width: 320px;
}
.overageBillingJobErrorDetails .commonTable .ant-table-tbody div.errorMessage {
  overflow: hidden;
  text-overflow: ellipsis;
}
.overageBillingJobErrorDetails .commonTable .errorMessagePopover {
  width: 100% !important;
  max-width: 800px !important;
}

/**************************************************
 * 一覧の各種日時欄の設定
 **************************************************/
.overageBillingJobErrorDetails .commonTable .ant-table-tbody td.datetimeColumn {
  white-space: pre-wrap;
}
